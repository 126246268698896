import PropTypes from 'prop-types'
import React from 'react'

import SanityImage from 'gatsby-plugin-sanity-image-alt'
import loadable from '@loadable/component'
import { useSanityImageShape } from '../helpers/useSanityImageShape'

const PortableText = loadable(() => import('./PortableText'))
const RandomShape = loadable(() => import('./RandomShape'))

const PageBuilderContentMedia = ({ block, raw, index }, props) => {
  const { reverseLayout, title, titleIsH1, image, body } = block

  const Heading = `${titleIsH1 ? 'h1' : 'h2'}`

  const imageShaped = useSanityImageShape(image)

  return (
    <section
      className={`container grid grid-cols-12 my-8 md:my-0 md:py-8 relative ${
        index === 0 ? `mt-16 md:mt-20` : ``
      }`}
    >
      <div
        className={`xl:col-start-2 col-span-12 xl:col-span-10 md:flex ${
          reverseLayout ? 'flex-row-reverse' : 'flex-row'
        }`}
      >
        <div
          className={`w-full mb-8 md:w-2/3 ${reverseLayout ? '' : 'md:mr-8'}`}
        >
          <div className="relative -mx-containerOffset md:mx-0 text-gray-100">
            <SanityImage
              {...imageShaped}
              className="object-cover w-full h-full border"
              alt={image.alt}
              width={692}
              height={320}
            />
            <RandomShape />
          </div>
        </div>
        <div
          className={`mx-auto w-full md:w-1/3 ${
            reverseLayout ? 'lg:mr-8' : ''
          }`}
        >
          <Heading className="text-17 md:text-24 font-bold">{title}</Heading>
          <PortableText blocks={body} isBasic />
        </div>
      </div>
    </section>
  )
}

PageBuilderContentMedia.propTypes = {
  block: PropTypes.object,
  raw: PropTypes.object,
  index: PropTypes.number,
}

PageBuilderContentMedia.defaultProps = {
  block: {},
  raw: {},
  index: 0,
}

export default PageBuilderContentMedia
